.resumeLink {
    font-weight: bold
}


#resumeImg {
    width: 70rem;
    position: relative;
    /* width: 350px;
    height: 550px; */
    background: white;
    border-radius: 0% 0% 0% 0% / 0% 0% 0% 0%;
    color: white;
    box-shadow: 20px 20px rgba(0, 0, 0, .15);
    transition: all .4s ease;
}

#resumeImg:hover {
    border-radius: 0% 0% 50% 50% / 0% 0% 5% 5%;
    box-shadow: 10px 10px rgba(0, 0, 0, .25);

}

@media screen and (max-width: 1024px) {
    #resumeImg {
        width: 35rem;
    }


}

@media screen and (max-width: 600px) {
    #resumeImg {
        width: 20rem;
    }
}