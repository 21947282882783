nav {
    background-color: rgb(100, 124, 126);
    z-index: 99;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
}


nav a {
    background: transparent;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    color: white;
    font-size: large;
}

nav a:hover {
    background: #85BDBF;
    color: black;
    font-weight: bold;
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    nav a {
        font-size: medium;
    }

    nav {
        width: 100%
    }
}

@media screen and (max-width: 600px) {
    nav a {
        font-size: small;
        gap: 0;
        padding: 0
    }

    nav {
        width: 20rem
    }
}