.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: .5rem;
    transition: var(--transition);
    background-color: #57737A;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    /* border: 25px solid #B88846; */
    /* border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E") 25; */
}

.github {
    background-color: black;
    border: none;
    color: white
}

.github:hover {
    opacity: .3;
    color: orange
}



.demo:hover {
    background: #85BDBF;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    max-height: 200px;
}

.portfolio__item p {
    margin: 0 0 1rem;
    text-align: center;
    color: rgb(206, 203, 203);
    font-size: medium;
}

.portfolio__item h3 {
    margin: 1.2rem 0 0;
    text-align: center;
}

.portfolio__item-image:hover {
    opacity: .3;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

#portfolioImg {
    width: 100%;
    height: 15rem;
}