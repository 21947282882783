footer {
    bottom: 0;
    z-index: 99;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    justify-content: center;

}

footer a {
    background: transparent;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    color: black;
    font-size: 1rem;
}

footer a:hover {
    background: var(--color-bg);

}

footer a.active {
    background: var(--color-bg);
}