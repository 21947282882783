p h1 {
    font-family: 'PlayfairDisplay', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

h2 {
    color: white;
    font-weight: bold;
    font-family: 'PlayfairDisplay', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}


body {

    background-image: linear-gradient(#85BDBF, #C9FBFF);

}

::-webkit-scrollbar {
    display: none;
}